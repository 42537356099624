import React from "react"
import tw, { styled } from "twin.macro"
import { H2, P, LightDiagonalBox } from "../components/page-elements"
import TeamCollab from "../images/team-collaboration.svg"
import DesignTeam from "../images/design-team.svg"
import ContentTeam from "../images/content-team.svg"
import CircleCheck from "../images/circle-check.svg"
import { Card } from "../components/page-elements"
import { Packages } from "../components/packages"
import { ContactForm } from "../components/contact-form"
import { Banner } from "../components/banner"
import { servicesList } from "../constants"
import Image from "../components/image"

const Container = tw.div`
    flex
    flex-col
    justify-center
    m-auto
    w-full
`

const ArticleHeader = styled(H2)`
  ${tw`mt-0 text-header`}
`

const ContentText = styled(P)`
  ${tw`text-body tracking-wide`}
`

const SummarySection = tw.div`
    flex
    flex-col
    max-w-screen-xl
    px-page-gutter
    m-auto
`

const SummaryRow = tw.div`
   w-full 
   flex
   flex-wrap
   justify-center
   my-8
   md:my-20
`

const SummaryCol = tw.div`
    w-full
    lg:w-1/2
    lg:flex-1
    mx-4
    my-4
`

const SummaryImg = tw.img`
    w-full

`

const Items = tw.div`
    flex
    flex-col
    w-full
    items-center
    justify-center
    mt-6
`

const Item = tw.div`
    h-10
    w-full

    md:w-2/3
    flex
    flex-row
    items-center
    my-4
`

const FeatureSection = tw.div`
    m-auto
    max-w-screen-xl
    px-page-gutter

`

const DetailedItems = styled.div`
  ${tw`justify-start`}
`

const DetailedItem = styled.div`
  ${tw` flex flex-row justify-start w-full items-start text-header my-4`}
`

const DetailedImageContainer = styled.div`
  ${tw`mr-2 `}
`

const DetailedContent = styled.div`
  ${tw`w-11/12`}
`

const SquareCard = styled(Card)`
  ${tw`w-64 h-64 m-4 py-4 px-2 flex flex-col justify-start text-center`}
`

const ServiceTitle = tw.div`
  font-bold
  text-header
  my-2
  text-lg
`

const ServiceBody = styled(P)`
  ${tw`my-0`}
`

const ServiceImage = tw(Image)`
  w-10
  mx-auto
  my-2
`

const PAGE_CONSTANTS = {
  title: "Services",
  header: "Our Services",
  description:
    "We're here to help the bottom line of your business by handling the things you don't want to. From websites, to search engine optimization, to automating every day tasks - we do it all.",
}

const ServicesPage = () => {
  return (
    <Container>
      <Banner
        title={PAGE_CONSTANTS.title}
        header={PAGE_CONSTANTS.header}
        description={PAGE_CONSTANTS.description}
      />
      <SummarySection>
        <SummaryRow>
          <SummaryCol>
            <SummaryImg src={TeamCollab} />
          </SummaryCol>
          <SummaryCol>
            <ArticleHeader>
              Web Design & Development Done Differently
            </ArticleHeader>
            <ContentText>
              Anyone can slap together a Wordpress, but a custom website will
              outperform any drag-and-drop page builder every time. We write
              your site’s codes line by line, giving us much more control over
              the design and helping your site perform faster and better than
              your competitors’.
            </ContentText>
            <ContentText>
              Custom websites built by hand out-perform most WordPress and other
              websites built with page builders. Becuase of this, Google rewards
              optimized website by ranking them higher in their search engine.
              We're experts and building websites that perform fast and look
              amazing.
            </ContentText>
          </SummaryCol>
        </SummaryRow>
        <SummaryRow className="md:flex-row-reverse">
          <SummaryCol>
            <SummaryImg src={DesignTeam} />
          </SummaryCol>
          <SummaryCol>
            <ArticleHeader>Modern Search Engine Optimization</ArticleHeader>
            <ContentText>
              There's no quick fix for Search Engine Optimization. It takes
              meticulous and a well thought out plan to get your website at the
              top of the search results. You'll want to be weary of anyone who
              says something different.
            </ContentText>
            <ContentText>
              We provide the latest search engine optimization techniques used
              in 2021:
            </ContentText>
            <Items>
              <Item>
                <img src={CircleCheck} className="mr-2" />
                <P className="text-body" style={{ marginTop: 0 }}>
                  Keyword-Focused Content & Blogs
                </P>
              </Item>
              <Item>
                <img src={CircleCheck} className="mr-2" />
                <P className="text-body" style={{ marginTop: 0 }}>
                  Mobile First Design & Development for the Best Experience
                </P>
              </Item>
              <Item>
                <img src={CircleCheck} className="mr-2" />
                <P className="text-body" style={{ marginTop: 0 }}>
                  Clean & Organized Code SEO Ranking
                </P>
              </Item>
              <Item>
                <img src={CircleCheck} className="mr-2" />
                <P className="text-body" style={{ marginTop: 0 }}>
                  Fully Responsive For All Devices Including; Mobile, Tablet, &
                  Desktop
                </P>
              </Item>
            </Items>
          </SummaryCol>
        </SummaryRow>
        <SummaryRow>
          <SummaryCol>
            <SummaryImg src={ContentTeam} />
          </SummaryCol>
          <SummaryCol>
            <div className="self-center">
              <ArticleHeader>Logos, Graphic Design, & Branding</ArticleHeader>
              <ContentText>
                We work with experienced designers to offer logo and graphic
                design services. We help businesses build their brand and can
                create a new logo or even touch up an existing logo.
              </ContentText>
            </div>

            <DetailedItems>
              <DetailedItem>
                <DetailedImageContainer>
                  <img src={CircleCheck} className="mr-2" />
                </DetailedImageContainer>
                <DetailedContent>
                  <P
                    className="text-header font-black"
                    style={{ marginTop: 0 }}
                  >
                    Logos Starting at $350
                  </P>
                  <ContentText style={{ marginTop: 0 }}>
                    Rates may increase based on number of revisions, time
                    involved, and complexity.
                  </ContentText>
                </DetailedContent>
              </DetailedItem>

              <DetailedItem>
                <DetailedImageContainer>
                  <img src={CircleCheck} className="mr-2" />
                </DetailedImageContainer>
                <DetailedContent>
                  <P
                    className="text-header font-black"
                    style={{ marginTop: 0 }}
                  >
                    Social Media Graphics
                  </P>
                  <ContentText style={{ marginTop: 0 }}>
                    Create a unique post or marketing campaign that will stand
                    out. Give us a call for plans and rates.
                  </ContentText>
                </DetailedContent>
              </DetailedItem>
            </DetailedItems>
          </SummaryCol>
        </SummaryRow>
      </SummarySection>

      <div className="relative w-full">
        <LightDiagonalBox top="-10%" />

        <FeatureSection>
          <SummaryRow>
            <SummaryCol className="flex flex-col justify-center">
              <ArticleHeader>Lifetime Updates For Your Website</ArticleHeader>
              <ContentText>
                Google's search algorithm is always going through revisions.
                Site's are ranked on modern standards that need to be updated
                every so often. We include lifetime updates to ensure these
                standards are met as trends change overtime.
              </ContentText>
            </SummaryCol>
            <SummaryCol>
              <div className="flex flex-wrap">
                {servicesList.map(({ icon, title, body }) => {
                  return (
                    <SquareCard key={title}>
                      <ServiceImage src={icon} alt="" />
                      <ServiceTitle>{title}</ServiceTitle>
                      <ServiceBody>{body}</ServiceBody>
                    </SquareCard>
                  )
                })}
              </div>
            </SummaryCol>
          </SummaryRow>
        </FeatureSection>
      </div>
      <div className="px-page-gutter max-w-screen-lg m-auto">
        <Packages />
      </div>

      <div className="mt-10 md:mt-32 md:-mb-64 w-full px-page-gutter">
        <ContactForm />
      </div>
    </Container>
  )
}

export default ServicesPage
